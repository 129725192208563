.cFooterWrapper {
  width: 100%;
  margin-top: 5rem;
}

.cFooterWrapper > hr {
  width: 100%;
  height: 1px;
  border: none;
  background: white;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
}

.cFooter {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.logo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-top: -7px;
}

.logo>img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo>span {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 7px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 16rem;
}
.detail {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 1rem;
    gap: 1rem;
}

.detail>span:nth-of-type(1)
{
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.pngLine:hover {
    cursor: pointer;
    color: var(--pink);
}

.pngLine {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon {
    width: 18px;
}

.copyRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    margin: 3rem 0;
}

@media screen and (max-width: 856px) {
  .logo>img {
    margin-top: -10px;
    width: 3rem;
    height: 3rem;
  }

  .logo>span {
    display: none;
  }

  .detail {
    font-size: 0.8rem;
  }

  .detail>span:nth-of-type(1) {
    font-size: 1rem;
  }

  .copyRight {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 768px) {
    .cFooter {
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      font-size: 1.7rem;
      gap: 2rem;
    }
  }

  @media screen and (max-width: 640px) {

    .cFooter {
      justify-content: center;
    }

    .logo {
      display: none;
    }
    /* .pngLine>.icon {
      display: none;
    } */
  }
}