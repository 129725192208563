.container {
    padding: 1.5rem;
    display: flex;
}
.logo {
    display: flex;
    align-items: center;
    flex: 1;
}

.logo>img {
    width: 2.5rem;
    height: 2.5rem;
}

.logo>span {
    font-weight: 600;
    letter-spacing: 0.3rem;
}

.right {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.menu-items {
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}
.menu-items>li:hover {
    cursor: pointer;
    color: #fe956f;
}

.search {
    width: 7rem;
    outline: none;
    height: 30%;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
}

.cart {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}
.bars {
    display: none;
}


@media screen and (max-width: 856px) {
    .search {
        display: none;
    }
    .right {
        gap: 1rem;
    }
}


@media screen and (max-width: 640px) {
    .right {
        position: fixed;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: var(--black);
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }

    .menu-items {
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars {
        display: block;
    }
    
    .search {
        display: none;
    }
}